/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import penIcon from "../../assets/icons/icon-pen.svg";
import emptyBox from "../../assets/images/empty.svg";
import loadingImage from "../../assets/images/loading.svg";
import productPlaceholder from "../../assets/images/placeholder-image.webp";
import { n_f } from "../../utils/helpers";

function ListProduct({ products }) {
  {
    const IMG_URL =
      process.env.REACT_APP_ENVIRONMENT == "dev"
        ? process.env.REACT_APP_IMAGE_SANDBOX_URL
        : process.env.REACT_APP_IMAGE_PRODUCT_URL;
    const [meta, setMeta] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputPage, setInputPage] = useState(1);
    const userInfo = useSelector((state) => state.userInfo);

    const navigate = useNavigate();
    const location = useLocation();

    if (isLoading)
      return (
        <section className="w-full h-80 flex justify-center items-center">
          <img src={loadingImage} alt="" />
        </section>
      );

    if (products.length < 1) {
      return (
        <section className="w-full flex flex-col justify-center items-center py-8 text-center font-medium gap-5">
          <div>
            <img src={emptyBox} alt="" className="w-52" />
          </div>
          <div>
            <p>
              We&apos;re sorry, it seems our products have gone into hiding.
            </p>
            <p>We&apos;ll try to coax them out soon.</p>
          </div>
        </section>
      );
    }

    return (
      <>
        <section className="grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 justify-items-center content-around gap-3 gap-y-16 mt-10">
          {products.map((product) => (
            <Link to={`/menu/detail/${product.id}`} key={product.id}>
              <section className="relative w-36 bg-white shadow-lg hover:shadow-xl duration-200 p-2 rounded-3xl">
                <img
                  //   src={product.img ?? productPlaceholder}
                  src={IMG_URL + `/${product.id}.webp`}
                  alt=""
                  className="aspect-square rounded-full object-cover mt-[-50%] w-full mb-3 shadow-lg"
                />
                {/* <div className="absolute top-0 right-0 bg-white p-2 rounded-3xl font-extrabold text-lg">
                20%
              </div> */}
                <div className="flex flex-col gap-5 content-between text-center">
                  <p className="font-black text-lg min-h-[102px]">
                    {product.name}
                  </p>
                  {/* <p className="font-bold end text-tertiary">
                    IDR {n_f(product.price)}
                  </p>
                  {Number(userInfo.role) > 1 && (
                    <NavLink
                      to={`/products/edit/${product.id}`}
                      className="bg-tertiary absolute bottom-0 right-0 w-7 h-7 rounded-full flex items-center justify-center hover:bg-primary-focus"
                    >
                      <img src={penIcon} className="w-4 h-4" />
                    </NavLink>
                  )} */}
                </div>
              </section>
            </Link>
          ))}
        </section>
      </>
    );
  }
}

export default ListProduct;
