import api from "./base";

export const createTransaction = (
  {
    payment_id = 1,
    delivery_id = 1,
    name = "B Nail Customer",
    phone = 0,
    address = "B Nail",
    notes = "Ship to B Nail",
    tip_amount = 0,
    voucher = "",
    discount_percent = 0,
    card = {},
  },
  products = [],
  //   token,
  controller
) => {
  const body = {
    payment_id,
    delivery_id,
    products,
    name,
    phone,
    address,
    notes,
    tip_amount,
    voucher,
    discount_percent,
    card,
  };
  return api.post(`/transactions`, body, {
    signal: controller.signal,
    // headers: { Authorization: `Bearer ${token}` },
  });
};

export const getTransactions = (
  { status_id = 1, page = 1 },
  token,
  controller
) => {
  return api.get("/transactions", {
    params: {
      status_id,
      page,
    },
    headers: { Authorization: `Bearer ${token}` },
    signal: controller.signal,
  });
};

export const setTransactionDone = (ids = [], token, controller) => {
  let convertedIds = ids.toString();
  if (typeof ids === "object") {
    convertedIds = ids.join(",");
  }
  console.log(convertedIds);
  return api.patch(
    "/transactions/changeStatus",
    {
      transactions: convertedIds,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
      signal: controller.signal,
    }
  );
};

export const getTransactionHistory = (
  { page = "1", limit = "9" },
  token,
  controller
) => {
  return api.get("/userPanel/transactions", {
    params: {
      page,
      limit,
    },
    headers: { Authorization: `Bearer ${token}` },
    signal: controller.signal,
  });
};

export const getTransactionDetail = (transactionId, token, controller) => {
  return api.get(`/transactions/${transactionId}`, {
    headers: { Authorization: `Bearer ${token}` },
    signal: controller.signal,
  });
};
