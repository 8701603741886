/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import penIcon from "../../assets/icons/icon-pen.svg";
import emptyBox from "../../assets/images/empty.svg";
import loadingImage from "../../assets/images/loading.svg";
import productPlaceholder from "../../assets/images/placeholder-image.webp";
import { n_f } from "../../utils/helpers";

function CloverOrder() {
  {
    return (
      <>
        {/* <div>
          <iframe
            src="https://sandbox.dev.clover.com/online-ordering/boba-tea--coffee-munster"
            title="Clover Order"
            width="100%"
            height="100%"
          ></iframe>
        </div> */}
      </>
    );
  }
}

export default CloverOrder;
